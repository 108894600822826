import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
const StyledWrapper = styled.div`
  margin: 100px 0px;

  @media (max-width: 600px) {
    margin-top: 50px;
  }

  .privacy-sub-title {
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }
  .item-space {
    margin-top: 5rem;
    margin-bottom: 5rem !important;
  }

  .li-subhead {
    font-size: 20px;
    font-weight: bold;
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  .react-desc a {
    color: black;
    text-decoration: underline;
  }
`;
const queryClient = new QueryClient();
export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Privacy />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/real-privacypage`);
};
const Privacy = () => {
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("privacy", fetchData);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        <SEO title="Privacy Notice & Use of Cookies – upReach" />
        <div className="container">
          {newData && (
            <h1 className="text-center mb-3 text-uppercase">{newData.Title}</h1>
          )}
          <ol>
            {newData &&
              newData.PrivacyComp.length > 0 &&
              React.Children.toArray(
                newData.PrivacyComp.map(item => (
                  <div>
                    <li className="privacy-sub-title">{item.title}</li>
                    <ReactMarkdown className="react-desc">
                      {item.subText}
                    </ReactMarkdown>
                  </div>
                ))
              )}
          </ol>
          {newData && (
            <h1 className="text-center item-space mb-3 text-uppercase">
              {newData.Title2}
            </h1>
          )}
          <ul>
            {newData &&
              newData.glossaryOfTerms.length > 0 &&
              React.Children.toArray(
                newData.glossaryOfTerms.map(item => (
                  <div>
                    <li className="privacy-sub-title">{item.title}</li>
                    <ReactMarkdown className="react-desc">
                      {item.subText}
                    </ReactMarkdown>
                  </div>
                ))
              )}
          </ul>
        </div>
      </StyledWrapper>
    </Layout>
  );
};
